//
// Soundcloud
// --------------------------------------------------
.soundcloud-player-classic {
  width: 100%;

  iframe {
    width: 100%;
    border: 0;
  }
}