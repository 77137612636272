/*
*
* Material Parallax
*/

.parallax-container {
  position: relative;
  overflow: hidden;
}

.material-parallax {
  position: absolute;
  top: 0;
  left: -1px;
  right: -1px;
  bottom: 0;
}

.ipad, .iphone {
  .material-parallax {
    background-attachment: scroll !important;
  }
}

.material-parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  max-width: inherit;
  min-width: inherit;
  min-height: 101%;
  transform: translate3d(-50%, 0, 0);
}

@include media-breakpoint-up(sm) {
  .material-parallax img {
    min-width: 101%;
  }
}

@include media-breakpoint-up(lg) {
  .material-parallax img {
    max-width: inherit;
  }
}

.parallax-content {
  position: relative;
  z-index: 1;
}