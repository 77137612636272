//
// Formstone Stepper Plugin
// --------------------------------------------------

.stepper{
  position: relative;
  display: inline-block;
  max-width: 70px;
  width: 70px;
  margin-bottom: 0;

  input[type="number"]{
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    -moz-appearance: textfield;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    color: $black;
    text-align: center;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &-arrow{
    position: absolute;
    top: 50%;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    line-height: 21px;
    font-size: 21px;
    font-family: 'Material Icons';
    cursor: pointer;
    color: $silver;
    transition: .3s all ease;

    &:hover{
      color: $primary
    }

    &.up{
      right: 0;
      text-align: left;

      &:before{
        content: '\e147';
      }
    }

    &.down{
      left: 0;
      text-align: right;

      &:before{
        content: '\e15c';
      }
    }
  }

  &.disabled{
    .stepper-arrow{
      pointer-events: none;
      opacity: .5;
    }
  }
}

.stepper-lg {
  .stepper {
    width: 90px;
    max-width: 90px;
  }
  .stepper-arrow {
    font-size: 24px;
  }

  input[type="number"]{
    min-height: 30px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
  }
}
