//
// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 10px, 15px, 20px, 30px, 40px, 50px, 60px, 70px, 85px, 100px);
@include indent-responsive(inset, left, padding-left, $grid-breakpoints, $insets);
@include indent-responsive(inset, right, padding-right, $grid-breakpoints, $insets);



// Elements
// -------------------------

.container + .container {
  margin-top: 60px;
}

h4 + .section-title {
  margin-top: 2px;
}

h4 + .comment-list {
  margin-top: 30px;
}

h3 + p {
  margin-top: 15px;
}

h3 + p.h4 {
  margin-top: 2px; 
}

h3 + .row {
  margin-top: 40px;
}
h3 +  * {
  margin-top: 40px;
}

.row + .row {
  margin-top: 60px;
}

* + .row.list-md-dashed {
  margin-top: 60px;
}

.row + .button-block {
  margin-top: 60px;
}

.slick-slider + .slick-slider.carousel-parent {
  margin-top: 35px;
}

.quote-left + .button-block {
  margin-top: 22px;
}

.aside-title + * {
  margin-top: 22px;
}

* + .button-group {
  margin-top: 25px;
}

// Grid
// -------------------------

// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 2px, 5px, 10px, 15px, 22px, 27px, 30px, 35px, 40px, 45px, 50px, 60px, 75px, 90px, 100px, 120px);

html .page{
  @include indent-responsive(offset, top, margin-top, $grid-breakpoints, $offsets);
}


// Shifts
.shift-sm-top-1 {
  @include media-breakpoint-up(md) {
    margin-top: -18px;
  }
  @include media-breakpoint-up(lg) {
    margin-top: -23px;
  }
}

.shift-md-top-1 {
  @include media-breakpoint-up(lg) {
    margin-top: -33px;
  }

  @include media-breakpoint-up(xl) {
    margin-top: -43px;
  }
}
// Range spacing
.row-0 {
  @include grid-offset(0px);
}

.row-15 {
  @include grid-offset(15px); 
}

.row-20 {
  @include grid-offset(20px);
}

.row-30 {
  @include grid-offset(30px);
}

.row-40 {
  @include grid-offset(40px);
}

.row-50 {
  @include grid-offset(50px);
}

.row-60 {
  @include grid-offset(60px);
}

@include media-breakpoint-up(sm) {
  .row-sm-50 {
    @include grid-offset(50px);
  }
  .row-sm-0 {
    @include grid-offset(0px);
  }

}

@include media-breakpoint-up(md) {
  .row-md-60 {
    @include grid-offset(60px);
  }
}

@include media-breakpoint-up(lg) {
  .row-md-30 {
    @include grid-offset(30px);
  }
}

@include media-breakpoint-up(xl) {
  .row-xl-100 {
    @include grid-offset(100px);
  }
  .row-xl-90 {
    @include grid-offset(90px);
  }
}
