//
// Dividers
// --------------------------------------------------
.divider {
  width: 49px;
  height: 2px;
  margin-left: auto;
  margin-right: auto;
  background: $primary;
}

.divider-fullwidth {
  height: 1px;
  width: 100%;
}

.divider-circle {
  position: relative;
  width: 100%;
  height: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    border-radius: 50px;
    background: $primary;
  }
}

.divider-triangle {
  height: 8px;
  background: url("../images/divider-triangle.png") no-repeat top center;
}

.hr {
  border: none;
  height: 1px;
  width: 100%;
}

// Sizing
.divider-md {
  height: 1px;
  width: 119px;
}

// Offsets
* + .divider-triangle,
* + .divider-circle {
  margin-top: 32px;
}

@include media-breakpoint-up(md) {
  * + .divider-circle {
    margin-top: 50px;
  }
}