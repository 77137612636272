// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
//
// Temporary, used for calculation variables should by named `$_variable-name`
//
// Table of Contents
//
// Color system
// Body
// Page
// Components
// Fonts
// Links
// Z-index master list
// Grid breakpoints
// Grid containers
// Grid columns
// Buttons
// Form
// Pagination 
// Tooltips


//
// Color system
//

// Gray colors
$white:  #fff;
$gray-100: #edeff4;
$gray-200: #f9f9f9;  // gray-lighter
$gray-300: #dedede;  // gray-light
$gray-400: #b7b7b7;
$gray-500: #9f9f9f;  // gray
$gray-600: #868e96;
$gray-700: #2c343b;
$gray-800: #2a2b2b;  // gray-dark
$gray-900: #00030a;  // gray-darker
$black:  #000;
 

$primary:           #6bc3f0; 
$secondary: $gray-900;
$secondary-1: #6baaf0; 

//
// Body
//
$body-bg:       $white;
$body-color:    $gray-500;
$header-bg:             $white; 
$footer-bg: 			      $black;

//$theme-colors: map-merge($theme-colors, ('primary': $primary));

// Accent colors
$danger-color:    #f5543f;
$success-color:   #98bf44;

$brand-success:           #58c476;
$brand-info:              #3e9cf6;
$brand-warning:           #c49558;
$brand-danger:            #fe4a21;


$keppel:          #42b294;
$red-orange:      #ff4b22; 

$red-orange-1:    #f8333c;
$dodger-blue:     #45a4ff;
$gorse:           #fde74c;
$gold:            #ffd400;
$cello:           #1e3953;

$cod-gray:        #111;
$mine-shaft:      #333;
$tundora:         #414141;
$abbey:           #535457;
$gunsmoke:        #767877;
$dusty-gray:      #9b9b9b;
$silver-chalice:  #ababab;
$silver:          #cdcdcd;
$gallery:         #eee;
$athens-gray:     #e5e8ef;
$iron:            #dcdde0;
$alto:            #ddd;
$porcelain:       #e5e7e9;
$gallery:         #ededed;
$whisper:         #f6f7fa;
$whisper-lighten: #f2f3f8;
$bismark:         #496a8a;
$san-juan:        #2e5275;
$cloud-burst:     #1e354a;
$old-gold:        #ecd746;
$bermuda-gray:    #6f8fad;
$contessa:        #e76752;
$athens-lighten:  #f2f3f7;
$athens-lighten-v2:  #f7f8fa;
$big-stone:       #1c2e3f;
$cape-cod:        #3a3c3e;
$abbey:           #464a4d;
$rolling-stone:   #74787C;
$athens-gray:     #F8F9FB;
$fuel-yellow:     #F0B922;

// Theme colors
$black-invariable:      #000;
$white-invariable:      #fff;

// Additional colors
$facebook:                #4d70a8;
$facebook-light:          #748abc;
$facebook-darken:         darken($facebook, 5%);

$twitter:                 #02bcf3;
$twitter-light:           #81cbdb;
$twitter-darken:          darken($twitter, 5%);
 
$google:                  #e2411e;
$google-light:            #de7272;
$google-darken:           darken($google, 5%);


//
// Page
//
$page-boxed-width:               1920px;
$page-boxed-background-color:    $gray-100;
$page-boxed-background-image:    url(../images/bg-pattern-boxed.png);


//
// Components
//
// Define common padding and border radius sizes and more.
$border-width: 1px;

$border-radius:          0;
$border-radius-lg:       6px;
$border-radius-sm:       3px;

$border-radius:        0;
$border-radius-large:       6px;
$border-radius-small:       3px;

$transition-base:        all .3s ease-in-out;

//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     15px;
$padding-base-horizontal:   35px;

$padding-large-vertical:    18px;
$padding-large-horizontal:  40px;

$padding-extra-large-vertical:    21px;
$padding-extra-large-horizontal:  50px;

$padding-small-vertical:    12px;
$padding-small-horizontal:  25px;

$padding-xs-vertical:       $padding-base-vertical*0.4;
$padding-xs-horizontal:     $padding-base-horizontal*0.4;

$line-height-large:         1.4444; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color:    $gray-800;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg:       $primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base:          4px;
//** Carets increase slightly in size for larger components.
$caret-width-large:         5px;

// Map
//
$map-height: 200px;
$map-xs-height: 250px;
$map-md-height: 450px;


// Custom
$shadow-area-1:   -3px 0px 50px -2px rgba(0, 0, 0, 0.09);
$shadow-area-2:   1px 1px 10px 0 rgba(0, 0, 0, 0.2);

$shadow-area-ambient: 0px 0px 1px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xxs: 1px 1px 1px rgba(0, 0, 0, 0.15);
$shadow-area-xs: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
$shadow-area-sm: -1px 0px 10px 0px rgba($tundora, .12);
$shadow-area-md: -3px 5px 12px 0px rgba($tundora, .16);
$shadow-area-lg: 0 5px 23px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xl: 0 12px 36px 0 rgba(0, 0, 0,.15);

//
// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:    "Roboto", Helvetica, Arial, sans-serif;
$font-family-sans-serif-1:  "Playfair Display", Helvetica, Arial, sans-serif;
$font-family-sans-serif-2:  "Playfair Display", Helvetica, Arial, sans-serif;
$font-family-serif:          Helvetica, Arial, sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-base:       $font-family-sans-serif;
$font-family-sec:        $font-family-sans-serif-1;

$font-weight-bold: 700;

// Base fonts
$font-size-base: 14px;
$font-size-lg:   18px;
$font-size-sm:   12px;

$_lh-base: 24px;
$_lh-lg:   24px;
$_lh-sm:   18px;

$font-weight-base: 400;

// ** < Calculated font values >
$line-height-base: ($_lh-base / $font-size-base);
$line-height-lg:   ($_lh-lg / $font-size-lg);
$line-height-sm:   ($_lh-sm / $font-size-sm);
// ** </ Calculated font values >


// Headings
$headings-font-family:   $font-family-base;
$headings-font-weight:   700;
$headings-line-height:   1.1;
$headings-color:         $black;

// Template Heading Values
$_h1-fsz: 104;
$_h2-fsz: 59;
$_h3-fsz: 45;
$_h4-fsz: 24;
$_h5-fsz: 33;
$_h6-fsz: 18;

$_h1-lh: 112;
$_h2-lh: 70;
$_h3-lh: 40;
$_h4-lh: 38;
$_h5-lh: 28;
$_h6-lh: 24;

// ** < Calculated Headings values >
$h1-font-size: $_h1-fsz * 1px;
$h2-font-size: $_h2-fsz * 1px;
$h3-font-size: $_h3-fsz * 1px;
$h4-font-size: $_h4-fsz * 1px;
$h5-font-size: $_h5-fsz * 1px;
$h6-font-size: $_h6-fsz * 1px;

$h1-line-height: ($_h1-lh / $_h1-fsz); 
$h2-line-height: ($_h2-lh / $_h2-fsz);
$h3-line-height: ($_h3-lh / $_h3-fsz);
$h4-line-height: ($_h4-lh / $_h4-fsz);
$h5-line-height: ($_h5-lh / $_h5-fsz);
$h6-line-height: ($_h6-lh / $_h6-fsz);
// ** </ Calculated Headings values >


// Icon Fonts
$fa:  'FontAwesome';
$mdi: 'Material Design Icons';
$mi:  'Material Icons';

$icon-font-path: "../fonts/";

//** File name for all font files.
$icon-font-name:           "glyphicons-halflings-regular";
$icon-font-awesome:        "fontawesome-webfont";
$icon-material-design:     "materialdesignicons-webfont";

//** Element ID within SVG icon file.
$icon-font-svg-id:         "glyphicons_halflingsregular";
$icon-font-awesome-id:     "fontawesomeregular";
$icon-material-design-id:  "materialdesigniconsregular";

// Lead
$lead-font-size:   24px;
$lead-line-height: 34px;
$lead-font-weight: 300;

// Hr
$hr-border-color: $gray-800;
$hr-border-width: $border-width;

// Mark
$mark-padding: 5px 10px;
$mark-bg: $primary;

$dt-font-weight: inherit;
$list-inline-padding: 5px;

// Code
$code-font-size:              90%;
$code-padding-y:              10px;
$code-padding-x:              5px;
$code-color:                  $gray-900;
$code-bg:                     $gray-100;


//
// Links
//
$link-color:            $primary;
$link-decoration:       none;
$link-hover-color:      darken($link-color, 15%); 
$link-press-color:      darken($link-color, 15%);
$link-hover-decoration: none;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown:           1000;
$zindex-sticky:             1020;
$zindex-fixed:              1030;
$zindex-modal-backdrop:     1040;
$zindex-modal:              1050;
$zindex-popover:            1060;
$zindex-tooltip:            1070;
// Custom
$zindex-rd-navbar:          1080;
$zindex-layout-panel:       1100;





// 
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs:   0,
  sm:   576px,
  md:   768px,
  lg:   992px,
  xl:   1200px,
  xxl:  1800px
);
 
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);


$screen-fullhd: 1600px;


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
    sm:   540px,
    md:   720px,
    lg:   960px,
    xl:   1170px
);
@include _assert-ascending($container-max-widths, "$container-max-widths");


//
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-gutter-width-narrow: 10px;


//
// Buttons
//

$input-btn-padding-y:       11px;
$input-btn-padding-x:       35px;
$input-btn-font-size:       14px;
$input-btn-line-height:     1.25;

$input-btn-padding-y-sm:    5px;
$input-btn-padding-x-sm:    25px;
$input-btn-line-height-sm:  1.5;

$input-btn-padding-y-lg:    12px;
$input-btn-padding-x-lg:    50px;
$input-btn-line-height-lg:  1.5;


$btn-font-family:                $font-family-sec;
$btn-font-weight:                700;
$btn-letter-spacing:             .1em;
$btn-text-transform:             uppercase;

$btn-font-size: 14;
$btn-lh-size: 24;

$btn-default-color:              $white;
$btn-default-bg:                 $black;
$btn-default-border:             $black;

$btn-primary-color:              $white;
$btn-primary-bg:                 $primary;
$btn-primary-border:             $primary;

$btn-success-color:              $white;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $brand-success;

$btn-info-color:                 $white;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $brand-info;

$btn-warning-color:              $white;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $brand-warning;

$btn-danger-color:               $white;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $brand-danger;

$btn-box-shadow:                 0;
$btn-focus-box-shadow:           0;
$btn-active-box-shadow:          0;

$btn-link-disabled-color:        $gray-300;

$btn-block-spacing-y:            .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              5px;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm; 

$btn-transition:                 all .15s ease-in-out;

//
// Forms
//
$form-input-height:             52px;
$form-input-lg-height:          60px; 
$form-input-sm-height:          40px;
//** Small `.form-input` height
$form-input-sm-height:             (floor($font-size-sm * $line-height-sm) + ($padding-small-vertical * 2) + 2);

$form-input-color:              $body-color;
$form-input-color-placeholder:  $form-input-color;

$form-input-background:         #fff;
$form-input-border-color:        $gray-300;
$form-input-border-radius:      $border-radius;
$form-input-background-disabled: $gray-200;

$form-input-font-size:          14px; 
$form-input-line-height:        24px;
$form-input-font-weight:        400; 

$form-input-border:             0px solid $form-input-border-color;
$form-input-padding-horizontal: 19px;
$form-input-line-height:        round($form-input-font-size * 1.7);

//** Border color for inputs on focus
$form-input-border-color-focus:             $primary;

$form-input-padding-vertical:   ($form-input-height - $form-input-line-height) / 2;
$form-input-padding:            $form-input-padding-vertical $form-input-padding-horizontal;


@if ($form-input-border != none) {
  $form-input-padding-vertical: ($form-input-height - $form-input-line-height - (nth($form-input-border, 1) * 2)) / 2;
  $form-input-padding: $form-input-padding-vertical - 2px $form-input-padding-horizontal $form-input-padding-vertical + 2px;
}


$form-textarea-default-height:       166px;
$form-textarea-default-min-height:   $form-input-height;
$form-textarea-default-max-height:   round($form-textarea-default-height * 1.5);


$form-feedback-focus-color:   $primary;
$form-feedback-valid-color:   $success-color;
$form-feedback-invalid-color: $danger-color;


//** Disabled cursor for form controls and buttons.
$cursor-disabled:                not-allowed;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding:                          10px 15px;
$nav-link-hover-bg:                         $gray-200;

$nav-disabled-link-color:                   $gray-300;
$nav-disabled-link-hover-color:             $gray-300;

//== Tabs
$nav-tabs-border-color:                     #ddd;

$nav-tabs-link-hover-border-color:          $gray-200;

$nav-tabs-active-link-hover-bg:             $body-bg;
$nav-tabs-active-link-hover-color:          $gray-500;
$nav-tabs-active-link-hover-border-color:   #ddd;

$nav-tabs-justified-link-border-color:            #ddd;
$nav-tabs-justified-active-link-border-color:     $body-bg;

//== Pills
$nav-pills-border-radius:                   $border-radius;
$nav-pills-active-link-hover-bg:            $component-active-bg;
$nav-pills-active-link-hover-color:         $component-active-color;


//
// Pagination
//
$pagination-padding-y:                 10px;
$pagination-padding-x:                 10px;
$pagination-font-size:                 14px;
$pagination-line-height:               24px;

$pagination-color:                     $black;
$pagination-bg:                        transparent;
$pagination-border-width:              2px;
$pagination-border-color:              $silver;

$pagination-hover-color:               $white;
$pagination-hover-bg:                  $primary;
$pagination-hover-border-color:        $primary;

$pagination-active-color:              $white;
$pagination-active-bg:                 $primary;
$pagination-active-border-color:       $primary;

$pagination-disabled-color:            $gray-200;
$pagination-disabled-bg:               $silver;
$pagination-disabled-border-color:     $silver;

//
// Tooltips
//
$tooltip-max-width:           200px;
$tooltip-color:               $white;
$tooltip-bg:                  $primary;
$tooltip-opacity:             1;
$tooltip-padding-y:           6px;
$tooltip-padding-x:           10px;
$tooltip-margin:              0;

$tooltip-arrow-width:         6px;
$tooltip-arrow-height:        6px;
$tooltip-arrow-color:         $tooltip-bg;


//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding:           4px;
//** Thumbnail background color
$thumbnail-bg:                $body-bg;
//** Thumbnail border color
$thumbnail-border:            #ddd;
//** Thumbnail border radius
$thumbnail-border-radius:     $border-radius;

//** Custom text color for thumbnail captions
$thumbnail-caption-color:     $body-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding:   9px;


//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical:     15px;
$padding-base-horizontal:   35px;

$padding-large-vertical:    18px;
$padding-large-horizontal:  40px;

$padding-extra-large-vertical:    21px;
$padding-extra-large-horizontal:  50px;

$padding-small-vertical:    12px;
$padding-small-horizontal:  25px;

$padding-xs-vertical:       $padding-base-vertical*0.4;
$padding-xs-horizontal:     $padding-base-horizontal*0.4;

$line-height-large:         1.4444; // extra decimals for Win 8.1 Chrome
$line-height-small:         1.5;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             $brand-success;
$state-success-bg:               #dff0d8;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text:                $brand-info;
$state-info-bg:                  #d9edf7;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text:             $brand-warning;
$state-warning-bg:               #fcf8e3;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text:              $brand-danger;
$state-danger-bg:                $brand-danger;
$state-danger-border:            $brand-danger;

// Template width (w/o units)
$template-width:          1920;

// Template width var for resizing
$template-width-min:      ($template-width+1)*1px;
$layout-boxed-width:      1920px;

//== Page loader variant 1
//
//##

$page-loader-v-1-bg:              $black;
$page-loader-v-1-padding:         0 25px;
//** spinner
$page-loader-v-1-spinner-width:   56px;
$page-loader-v-1-spinner-height:  56px;

//== RD Navbar
//
//##

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $gray-900;
$rd-navbar-background: $white;
$rd-navbar-shadow: none;
$rd-navbar-border: 1px solid $porcelain;
$rd-navbar-width: 1200px;

//RD Navbar panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

//RD Navbar nav
$rd-navbar-nav-min-width: 270px;
$rd-navbar-nav-color: $rd-navbar-color;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: $primary;
$rd-navbar-nav-hover-background: transparent;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

//RD Navbar dropdown
$rd-navbar-dropdown-width: 235px;
$rd-navbar-dropdown-background: $white;
$rd-navbar-dropdown-item-color: $body-color;
$rd-navbar-dropdown-item-background: transparent;
$rd-navbar-dropdown-item-hover-color: $primary;
$rd-navbar-dropdown-item-hover-background: transparent;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: $white;
$rd-navbar-megamenu-item-color: $body-color;
$rd-navbar-megamenu-item-background: $rd-navbar-dropdown-item-color;
$rd-navbar-megamenu-item-hover-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-megamenu-item-hover-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar togles
$rd-navbar-toggle-preset: "button-lines-arrow-2";
$rd-navbar-search-toggle-preset: "search-preset-1";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";

//RD Navbar Fixed
$rd-navbar-fixed-height: $rd-navbar-min-height;
$rd-navbar-fixed-line-height: $rd-navbar-min-line-height;
$rd-navbar-fixed-font-size: $rd-navbar-min-font-size;
$rd-navbar-fixed-panel-color: $body-color;
$rd-navbar-fixed-toggle-color: $black;
$rd-navbar-fixed-panel-background: $white;
$rd-navbar-fixed-shadow: none;
$rd-navbar-fixed-border: $rd-navbar-border;

$rd-navbar-fixed-panel-item-color: $abbey;
$rd-navbar-fixed-panel-item-hover-color: $white;
$rd-navbar-fixed-panel-item-background: $rd-navbar-fixed-panel-background;
$rd-navbar-fixed-panel-item-hover-background: $primary;

$rd-navbar-fixed-submenu-toggle-color: $black;
$rd-navbar-fixed-submenu-toggle-hover-color: $primary;
$rd-navbar-fixed-submenu-toggle-focus-color: $white;
//RD Navbar static indents
$navbar-static-nav-indent: 10px;

//RD Navbar fullwidth indents
$navbar-fullwidth-nav-indent: 20px;

$rd-navbar-input-color: $dusty-gray;
$rd-navbar-input-border: 1px solid $porcelain; 
$rd-navbar-input-border-radius: 3px;

//== Type
//
//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted:                  $gray-300;
//** Abbreviations and acronyms border color
$abbr-border-color:           $gray-300;
//** Headings small color
$headings-small-color:        $gray-300;
//** Blockquote small color
$blockquote-small-color:      $gray-300;
//** Blockquote font size
$blockquote-font-size:        ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color:     $gray-200;
//** Page header border color
$page-header-border-color:    $gray-200;
//** Width of horizontal description list titles
$dl-horizontal-offset:        $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
//$dl-horizontal-breakpoint:    $grid-float-breakpoint;
//** Horizontal line color.
$hr-border:                   $gray-800;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            17px 25px 18px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
$table-bg:                      transparent;
$table-header-bg:               $whisper;
//** Background color used for `.table-striped`.
$table-bg-accent:               $whisper;
//** Background color used for `.table-hover`.
$table-bg-hover:                $whisper;
$table-bg-active:               $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color:            #d9d9d9;


//== Dropdowns
//

// $dropdown-font-size: 14;
// $dropdown-lh-size: 36;

//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg:                    #fff;
//** Dropdown menu `border-color`.
$dropdown-border:                rgba(0,0,0,.15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border:       #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg:            #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color:            $gray-800;
//** Hover color for dropdown links.
$dropdown-link-hover-color:      darken($gray-800, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         #f5f5f5;

//** Active dropdown menu item text color.
$dropdown-link-active-color:     $component-active-color;
//** Active dropdown menu item background color.
$dropdown-link-active-bg:        $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   $gray-300;

//** Text color for headers within dropdown menus.
$dropdown-header-color:          $gray-300;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000;
