@font-face {
  font-family: 'Thin Regular';
  src: url('../fonts/Thin-Regular.eot?66413286');
  src: url('../fonts/Thin-Regular.eot?66413286#iefix') format('embedded-opentype'),
       url('../fonts/Thin-Regular.woff?66413286') format('woff'),
       url('../fonts/Thin-Regular.ttf?66413286') format('truetype'),
       url('../fonts/Thin-Regular.svg?66413286#thin') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="thin-icon-"]:before,
[class*=" thin-icon-"]:before,
.thin-ico{
    font-family: "Thin Regular";
    font-weight: 400;
    font-style: normal;
    font-size: inherit;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
 
.thin-icon-volume-on:before {
    content: '\e800';
}

.thin-icon-gift:before {
    content: '\e801';
}

.thin-icon-cup:before {
    content: '\e802';
}

.thin-icon-folder:before {
    content: '\e803';
}

.thin-icon-dublicate:before {
    content: '\e804';
}

.thin-icon-tag:before {
    content: '\e805';
}

.thin-icon-chat:before {
    content: '\e806';
}

.thin-icon-clock:before {
    content: '\e807';
}

.thin-icon-microphone:before {
    content: '\e808';
}

.thin-icon-map-marker:before {
    content: '\e809';
}

.thin-icon-mobile:before {
    content: '\e80a';
}

.thin-icon-cloud-charge:before {
    content: '\e80b';
}

.thin-icon-resize:before {
    content: '\e80c';
}

.thin-icon-cake:before {
    content: '\e80d';
}

.thin-icon-case:before {
    content: '\e80e';
}

.thin-icon-address:before {
    content: '\e80f';
}

.thin-icon-phone-support:before {
    content: '\e810';
}

.thin-icon-fullscreen:before {
    content: '\e811';
}

.thin-icon-db:before {
    content: '\e812';
}

.thin-icon-music:before {
    content: '\e813';
}

.thin-icon-network:before {
    content: '\e814';
}

.thin-icon-db-network:before {
    content: '\e815';
}

.thin-icon-dropbox-upload:before {
    content: '\e816';
}

.thin-icon-phone-call:before {
    content: '\e817';
}

.thin-icon-briefcase-2:before {
    content: '\e818';
}

.thin-icon-card:before {
    content: '\e819';
}

.thin-icon-support:before {
    content: '\e81a';
}

.thin-icon-pull:before {
    content: '\e81b';
}

.thin-icon-desktop:before {
    content: '\e81c';
}

.thin-icon-pass:before {
    content: '\e81d';
}

.thin-icon-picture:before {
    content: '\e81e';
}

.thin-icon-email:before {
    content: '\e81f';
}

.thin-icon-push:before {
    content: '\e820';
}

.thin-icon-house:before {
    content: '\e821';
}

.thin-icon-download:before {
    content: '\e822';
}

.thin-icon-storage:before {
    content: '\e823';
}

.thin-icon-milk:before {
    content: '\e824';
}

.thin-icon-external-right:before {
    content: '\e825';
}

.thin-icon-email-open:before {
    content: '\e826';
}

.thin-icon-planet:before {
    content: '\e827';
}

.thin-icon-pointer:before {
    content: '\e828';
}

.thin-icon-email-search:before {
    content: '\e829';
}

.thin-icon-external-left:before {
    content: '\e82a';
}

.thin-icon-shirt:before {
    content: '\e82b';
}

.thin-icon-document-edit:before {
    content: '\e82c';
}

.thin-icon-document-delete:before {
    content: '\e82d';
}

.thin-icon-money:before {
    content: '\e82e';
}

.thin-icon-eye:before {
    content: '\e82f';
}

.thin-icon-settings:before {
    content: '\e830';
}

.thin-icon-arrow-bottom-right:before {
    content: '\e831';
}

.thin-icon-arrow-right:before {
    content: '\e832';
}

.thin-icon-flag:before {
    content: '\e833';
}

.thin-icon-star:before {
    content: '\e834';
}

.thin-icon-calculator:before {
    content: '\e835';
}

.thin-icon-safe:before {
    content: '\e836';
}

.thin-icon-cart:before {
    content: '\e837';
}

.thin-icon-bullhorn:before {
    content: '\e838';
}

.thin-icon-anchor:before {
    content: '\e839';
}

.thin-icon-globe:before {
    content: '\e83a';
}

.thin-icon-statistics:before {
    content: '\e83b';
}

.thin-icon-thumb-up:before {
    content: '\e83c';
}

.thin-icon-headphones:before {
    content: '\e83d';
}

.thin-icon-bell:before {
    content: '\e83e';
}

.thin-icon-study:before {
    content: '\e83f';
}

.thin-icon-cart-add:before {
    content: '\e840';
}

.thin-icon-cart-delete:before {
    content: '\e841';
}

.thin-icon-satelite:before {
    content: '\e842';
}

.thin-icon-home:before {
    content: '\e843';
}

.thin-icon-time:before {
    content: '\e844';
}

.thin-icon-book:before {
    content: '\e845';
}

.thin-icon-bookmark:before {
    content: '\e846';
}

.thin-icon-key:before {
    content: '\e847';
}

.thin-icon-timer:before {
    content: '\e848';
}

.thin-icon-saturn:before {
    content: '\e849';
}

.thin-icon-notes:before {
    content: '\e84a';
}

.thin-icon-ambulance:before {
    content: '\e84b';
}

.thin-icon-briefcase:before {
    content: '\e84c';
}

.thin-icon-layers:before {
    content: '\e84d';
}

.thin-icon-delivery:before {
    content: '\e84e';
}

.thin-icon-tint:before {
    content: '\e84f';
}

.thin-icon-trash:before {
    content: '\e850';
}

.thin-icon-lightbulb:before {
    content: '\e851';
}

.thin-icon-calendar:before {
    content: '\e852';
}

.thin-icon-chart:before {
    content: '\e853';
}

.thin-icon-documents:before {
    content: '\e854';
}

.thin-icon-checklist:before {
    content: '\e855';
}

.thin-icon-camera-web:before {
    content: '\e856';
}

.thin-icon-camera:before {
    content: '\e857';
}

.thin-icon-lock:before {
    content: '\e858';
}

.thin-icon-umbrella:before {
    content: '\e859';
}

.thin-icon-user:before {
    content: '\e85a';
}

.thin-icon-love:before {
    content: '\e85b';
}

.thin-icon-hanger:before {
    content: '\e85c';
}

.thin-icon-car:before {
    content: '\e85d';
}

.thin-icon-cloth:before {
    content: '\e85e';
}

.thin-icon-box:before {
    content: '\e85f';
}

.thin-icon-attachment:before {
    content: '\e860';
}

.thin-icon-cd:before {
    content: '\e861';
}

.thin-icon-love-broken:before {
    content: '\e862';
}

.thin-icon-volume-off:before {
    content: '\e863';
}
