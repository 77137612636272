//
// Pagination
// --------------------------------------------------
$pagination-size: 52px;
$pagination-offset: 20px;

.pagination-custom {
  position: relative;
  display: inline-block;
  @include spacing(4px);

  .page-item {
    display: inline-block;
    line-height: 1;

    &:first-child, &:last-child {
      .page-link  {
        padding-left: 25px;
        padding-right: 25px;
        color: $primary;
        border-color: $primary;
      }
    }
  }

  .page-item .page-link  {
    display: block;
    width: auto;
    min-width: $pagination-size;
    height: $pagination-size;
    padding: 10px 20px;
    border: 2px solid;
    border-radius: 0;
    font: 700 14px/14px $font-family-base;
    text-transform: uppercase;
    vertical-align: middle;

    &:after {
      content: '';
      height: 108%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }

    &,
    &:active,
    &:focus {
      color: $pagination-color;
      background: $pagination-bg;
      border-color: $pagination-border-color;
    }

    &:hover {
      color: $pagination-hover-color;
      background: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }
  }

  .page-item.disabled,
  .page-item.active {
    pointer-events: none;
  }

  .page-item.active .page-link  {
    color: $pagination-active-color;
    background: $pagination-active-bg;
    border-color: $pagination-active-border-color;
  }

  .page-item.disabled .page-link  {
    color: $pagination-disabled-color;
    background: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}
