//
// Icons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.icon {
  display: inline-block;
  text-align: center;
  &:before {
    display: inline-block;
    font-weight: 400;
    font-style: normal;
    speak: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

[class*='icon-circle'] {
  border-radius: 50%;
  overflow: hidden;
}

[class*='icon-round'] {
  border-radius: 4px;
  overflow: hidden;
}

// Alternate icons
// --------------------------------------------------
.page {
  .icon-default {
    color: $body-color;
  }

  .icon-black {
    color: $black;
  }

  .icon-primary {
    color: $primary;
  }

  .icon-dusty-gray {
    color: $dusty-gray;
  }

  .icon-gunsmoke {
    color: $gunsmoke;
  }

  .icon-tundora {
    color: $tundora;
  }

  // Fill
  .icon-gray-dark-filled {
    color: $white;
    background: $gray-800;
  }

  .icon-san-juan-filled {
    color: $white;
    background: $san-juan;
  }

  .icon-silver-chalice-filled {
    color: $white;
    background: $silver-chalice;
  }

  .icon-abbey-filled {
    color: $white;
    background: $abbey;
  }

  .icon-white {
    color: $white;
  }

  a {

    &.icon-default {
      @include link($body-color, $primary);
    }

    &.icon-primary {
      @include link($primary, $white);
    }

    &.icon-abbey-filled {
      &:hover {
        color: $white;
        background: $primary;
      }
    }

    &.icon-tundora-inverse {
      @include link($tundora, $white);
    }

    &.icon-gray-dark-filled {
      &,
      &:active,
      &:focus {
        color: $white;
        background: $gray-800;
      }

      &:hover {
        color: $white;
        background: $primary;
      }
    }

    &.icon-silver-chalice-filled {
      &,
      &:active,
      &:focus {
        color: $white;
        background: $silver-chalice;
      }

      &:hover {
        color: $white;
        background: $primary;
      }
    }

    &.icon-san-juan-filled {
      &,
      &:active,
      &:focus {
        color: $white;
        background: $san-juan;
      }

      &:hover {
        color: $white;
        background: $primary;
      }
    }
  }
}


// Button Sizes
// --------------------------------------------------
.page {
  .icon-xxs {
    @include icon-sizing(18px);

    &-small {
      @include icon-sizing(16px);
    }

    &-smaller {
      @include icon-sizing(14px);
    }

    &-smallest {
      @include icon-sizing(12px);
    }
  }

  .icon-xs {
    @include icon-sizing(22px);

    &-smaller {
      @include icon-sizing(20px);
    }
  }

  .icon-sm {
    @include icon-sizing(24px);

    &-custom {
      @include icon-sizing(24px);
      @include media-breakpoint-up(lg) {
        @include icon-sizing(30px);
      }
    }
  }

  .icon-md {
    @include icon-sizing(36px);

    &-custom {
      @include icon-sizing(26px);
      @include media-breakpoint-up(lg) {
        @include icon-sizing(36px);
      }
    }

    &-smaller {
      @include icon-sizing(30px);
    }
  }

  .icon-lg {
    @include icon-sizing(45px);

    &-variant-1 {
      @include icon-sizing(42px);
    }

    &-variant-2 {
      @include icon-sizing(44px);
    }

    &-bigger {
      @include icon-sizing(50px);
    }
  }

  .icon-xl {
    @include icon-sizing(60px);
  }


  [class*='icon-round'],
  [class*='icon-circle'] {
    &.icon-xxs-smallest {
      width: 26px;
      height: 26px;
      line-height: 26px;
    }
  }
}

// Mods
.icon-shift-1 {
  position: relative;
  top: 2px;
}

.icon-shift-2 {
  position: relative;
  top: 2px;

  @include media-breakpoint-up(lg) {
    top: 4px;
  }
}

// Custom icons
//-------------

.icon-1:before,
.icon-2:before,
.icon-4:before,
.icon-5:before,
.icon-6:before,
.icon-3:before {
  content: '';
  display: inline-block;
  width: 40px;
  height: 40px;
}

.icon-1:before {
  background: url("../images/icon-1.png") no-repeat top left;
}

.icon-2:before {
  background: url("../images/icon-2.png") no-repeat top left;
}

.icon-3:before {
  background: url("../images/icon-3.png") no-repeat top left;
}

.icon-4:before {
  background: url("../images/icon-4.png") no-repeat top left;
}

.icon-5:before {
  background: url("../images/icon-5.png") no-repeat top left;
}

.icon-6:before {
  background: url("../images/icon-6.png") no-repeat top left;
}
