// Spacer
@mixin spacing($x, $y : $x) {
  position: relative;
  transform: translateY(-$y);
  margin-bottom: -$y;

  > * {
    margin-top: $y;
  }

  > *:not(:last-child) {
    margin-right: $x;
  }
}

@mixin grid-offset($offset) {
  margin-bottom: -$offset;

  &:empty {
    margin-bottom: 0;
  }

  > * { margin-bottom: $offset; }
}