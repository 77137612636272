/*
* @subsection   RD Google Map
*
* @description  Describes style declarations for RD Google Map extension
*
* @author       Evgeniy Gusarov
* @link         https://ua.linkedin.com/pub/evgeniy-gusarov/8a/a40/54a
* @version      1.0.0
*/
$map-height: 200px;
$map-xs-height: 250px;
$map-sm-height: 400px;
$map-lg-height: 450px;
$map-xl-height: 532px;

.rd-google-map {
  color: #333;

  &__model{
    height: $map-xs-height;

    img {
      max-width: none !important;
    }

    @include media-breakpoint-up(sm) {
      height: $map-xs-height;
    }

    @include media-breakpoint-up(md) {
      height: $map-sm-height;
    }

    @include media-breakpoint-up(xl) {
      height: $map-lg-height;
    }

    @include media-breakpoint-up(xxl) {
      height: $map-xl-height;
    }
  }

  &__locations{
    display: none;
  }
}

