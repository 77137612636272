/**
* Template Style
*
* [Table of contents]
*   1 Bootstrap Framework
*     1.1  Normalize
*     1.2  Scaffolding
*     1.3  Type
*     1.4  Code
*     1.5  Tables
*     1.6  Forms
*     1.7  Buttons
*     1.8  Grids
*     1.9  Component animations
*     1.10 Dropdowns
*     1.11 Button Groups
*     1.12 Input Groups
*     1.13 Navs
*     1.14 Navbar
*     1.15 Breadcrumbs
*     1.16 Pagination
*     1.17 Pager
*     1.18 Labels
*     1.19 Badges
*     1.20 Jumbotron
*     1.21 Thumbnails
*     1.22 Alerts
*     1.23 Progress bars
*     1.24 Media
*     1.25 List Group
*     1.26 Panels
*     1.27 Responsive Embed
*     1.28 Wells
*     1.29 Close
*     1.30 Glyphicons
*     1.31 Modals
*     1.32 Tooltip
*     1.33 Popovers
*     1.34 Carousel
*     1.35 Utilities
*     1.36 Responsive Utilities
*
*   2 Bootstrap Toolkit Styles
*     2.1  Reset
*     2.2  Flex Grid System
*     2.3  Responsive Text System
*     2.4  Responsive Pulls System
*     2.5  Responsive Visibility System
*     2.6  Type Addons
*     2.7  Alerts Addons
*     2.8  Text Styling
*     2.9  Icons
*     2.10 Font Awesome
*     2.11 Material Design Icons
*     2.12 Thumbnail Addons
*     2.13 Form Addons
*     2.14 Responsive Units
*     2.15 Stacktables
*     2.16 Sections
*     2.17 Scaffolding Addons
*     2.18 Offsets
*
*   3 Plugins
*     3.1  Animate
*     3.2  Counter
*     3.3  Countdown
*     3.4  Isotope
*     3.5  Owl Carousel
*     3.6  RD Navbar
*     3.7  RD Parallax
*     3.8  RD Event Calendar
*     3.9  RD Google Maps
*     3.10 RD Search
*     3.11 RD Twitter
*     3.12 Swiper Slider
*     3.13 ToTop Button
*     3.14 Progress Bars
*     3.15 Timecircles
*     3.16 Slick carousel
*     3.17 FS Stepper
*     3.18 Bootstrap-material-datetimepicker
*     3.19 Photoswipe
*     3.20 Select 2
*     3.21 Magnific popup
*     3.22 Soundcloud
*     3.23 Scrollspy
*/

/*
 * Bootstrap Framework
 */



/*
 * Bootstrap Toolkit
 */

@import "custom/mixins-custom";

// Additional Style Reset
@import "custom/reset";

// Toolkit Core
//@import "custom/flex-grid";
//@import "custom/text-responsive";
@import "custom/text-alignment";
//@import "custom/pull-responsive";
//@import "custom/visibility-responsive";

// Toolkit Components
@import "custom/scaffolding-custom";
@import "custom/type-custom.scss";
@import "custom/alerts-custom.scss";
@import "custom/text-styling";
@import "custom/buttons-custom";
@import "custom/icons";
@import "custom/font-awesome";
@import "custom/material-icons";
@import "custom/materialdesignicons";
@import "custom/fl-flat-icons-set-2";
@import "custom/fl-bigmug-line"; 
@import "custom/fl-36-slim-icons";
@import "custom/fl-puppets";
@import "custom/thumbnails-custom";
@import "custom/figures-custom";
@import "custom/forms-custom";
@import "custom/unit-responsive"; 
@import "custom/stacktable";
@import "custom/sections";
@import "custom/groups";
@import "custom/utilities-custom";
@import "custom/breadcrumbs-custom";
@import "custom/pagination-custom";
@import "custom/label-custom";
@import "custom/quotes-custom";
@import "custom/box-custom";
@import "custom/post-utilities";
@import "custom/comments-utilities";
@import "custom/page-title";
@import "custom/page-loaders";
@import "custom/shop-utilities";
@import "custom/one-screen-page";
@import "custom/offsets";
@import "custom/links";
@import "custom/lists";
@import "custom/wrappers";
@import "custom/dividers";
@import "custom/fl-puppets";
@import "custom/nav-custom";
@import "custom/panels-custom";
@import "custom/tables-custom";
@import "custom/jumbotron-custom";
@import "custom/thin";


/*
 * Custom Plugins
 */
@import "plugins/animate.scss";
@import "plugins/counter.scss";
@import "plugins/countdown";
@import "plugins/isotope.scss";
@import "plugins/owl-carousel.scss";
@import "plugins/rd-navbar.scss";
@import "plugins/rd-parallax.scss";
@import "plugins/rd-google-map.scss";
@import "plugins/rd-search.scss";
@import "plugins/rd-twitter";
@import "plugins/swiper.scss";
@import "plugins/ui-to-top.scss";
@import "plugins/progress-bar.scss"; 
@import "plugins/timecircles.scss";
@import "plugins/slick.scss";
@import "plugins/jquery.fs.stepper";
@import "plugins/bootstrap-material-datetimepicker";
@import "plugins/photoswipe";
@import "plugins/select2";
@import "plugins/magnific-popup";
@import "plugins/soundcloud";
@import "plugins/scrollspy.scss";
@import "plugins/material-parallax"; 