//
// RD Navbar default
// --------------------------------------------------

//html, body  {
//  pointer-events: none !important;
//  height: 100vh;
//  overflow: hidden;
//}
//
//.page-head {
//  pointer-events: none !important;
//
//  .rd-navbar {
//    pointer-events: auto !important;
//  }
//}

.rd-navbar-default {

  // RD Navbar Nav
  .rd-navbar-nav {
    > li > a {
      font-family: $font-family-base;
      font-weight: 700;
      line-height: 1.2;
      text-transform: uppercase;
      color: $rd-navbar-nav-color;
    }
  }

  // RD Navbar search
  .rd-navbar-search {
    .form-input,
    .form-label {
      font-size: 16px;
      line-height: 1.3;
      color: $rd-navbar-input-color;
    }

    .form-label {
      top: 18px;
      left: 22px;
    }

    .form-input {
      padding: 7px 45px 10px 22px;
      height: auto;
      min-height: 20px;
      border: $rd-navbar-input-border;
      border-radius: $rd-navbar-input-border-radius;
    }

    .rd-navbar-search-toggle,
    .rd-search-submit {
      @include link($gray-900, $primary);
      font-size: 25px;

      &,
      &:before {
        content: '\e8b6';
        font-family: 'Material Icons';
      }
    }

    .rd-navbar-search-toggle {
      position: relative;

      &:after {
        content: '\e5cd';
      }
    }
  }

  // Mobile & tablet variant
  &.rd-navbar-fixed {
    // RD Navbar Shop
    .rd-navbar-shop {
      position: fixed;
      top: 15px;
      right: 15px;
      z-index: 1001;
    }

    // RD Navbar Search
    .rd-navbar-search {
      .rd-navbar-search-toggle {
        display: none;
      }
    }
  }

  // Desktop variant
  &.rd-navbar-static {
    .rd-navbar-inner,
    .rd-navbar-group {
      @include display-flex;
      @include flex-direction(row);
      @include align-items(center);
      @include flex-wrap(nowrap);
    }

    // RD Navbar Inner
    .rd-navbar-inner {
      @include justify-content(space-between);
      padding: 44px 15px 42px;
      font-size: 0;
      line-height: 0;
    }

    // RD Navbar Panel
    .rd-navbar-panel {
      min-width: 100px;
    }

    // RD Navbar Group
    .rd-navbar-group {
      @include justify-content(flex-end);
    }

    // RD Navbar Nav Inner
    .rd-navbar-nav-inner {
      @include display-flex;
      @include flex-direction(row-reverse);
      @include flex-wrap(nowrap);
      @include align-items(center);
      @include justify-content(flex-start);
      margin-right: 12px;
    }

    // RD Navbar Nav
    .rd-navbar-nav {
      z-index: 0;
      margin-right: 40px;
      transition: .25s;

      > li + li {
        margin-left: 32px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 77px;

        > li + li {
          margin-left: 48px;
        }
      }
    }

    // RD Navbar Toggle
    .rd-navbar-toggle {
      .rd-navbar-nav {
        > li {
          .rd-navbar-toggle {
            display: none;
          }
        }
      }
    }

    .rd-navbar-nav > li > .rd-navbar-dropdown {
      margin-top: 54px;
    }

    // RD Navbar states
    &.rd-navbar--is-clone,
    &.rd-navbar--is-stuck {
      .rd-navbar-inner {
        padding: 18px 15px;
      }

      .rd-navbar-nav > li > .rd-navbar-dropdown {
        margin-top: 49px;
      }
    }
  }
}