//
// Sections
// --------------------------------------------------

.section-relative {
  position: relative;
}


@include media-breakpoint-up(md) {
  .section-with-counters{
    padding-top: 1px;
    padding-bottom: 1px;
    > div{
      position: relative;
      box-shadow:         2px 2px 27px 0px rgba(1, 3, 4, 0.35);
      z-index: 2;
      margin-top: -30px;
      margin-bottom: -30px;
    }
  }
}


.section-image-aside {

  @include media-breakpoint-up(md) {
    position: relative;
  }

  &-img {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 190%;
    -webkit-background-size: cover;
    background-size: cover;

    @include media-breakpoint-up(md) {
      width: 50vw;
    }
  }
}

.section-image-aside-left {
  .section-image-aside-img {
    right: -50%;

    @include media-breakpoint-up(md) {
      right: 0;
    }
  }
}

.section-image-aside-right {
  .section-image-aside-img {
    left: -50%;

    @include media-breakpoint-up(md) {
      left: 0;
    }
  }
}

// Section Spacing
// -------------------------

$insets: (15px, 30px, 35px, 40px, 45px, 50px, 60px, 66px, 75px, 90px, 100px, 120px, 130px, 145px, 165px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $grid-breakpoints, $insets);
@include indent-responsive(section, top, padding-top, $grid-breakpoints, $insets);
@include indent-responsive(section, bottom, padding-bottom, $grid-breakpoints, $insets);