//
// Slick carousel
// --------------------------------------------------

// Base styles
// --------------------------------------------------

$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  min-height: 1px;
  [dir="rtl"] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

// Appearance styles
// --------------------------------------------------

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: rgba($black, .6);
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 999;

  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: $slick-font-family;
  font-size: 20px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
  [dir="rtl"] & {
    left: auto;
    right: 0;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: 0;
  [dir="rtl"] & {
    left: 0;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-slider {
  margin-bottom: 30px;

  *:focus {
    outline: 0;
  }
}

.slick-dots {
  display: block;
  margin-top: 20px;
  list-style: none;
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  line-height: 0;
  word-spacing: 0;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      @include reset-button;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      background: $silver-chalice;
    }

    &.slick-active button,
    &:hover button {
      background: $primary;
    }
  }
}

.slick-dots-variant-1 {
  .slick-dots {
    li {
      button {
        height: 12px;
        width: 12px;
        background: rgba($cape-cod, .5);
        transition: .33s all ease;
        position: relative;

        &:after{
          content: '';
          position: absolute;
          top:50%;
          left: 50%;
          width: 19px;
          height: 19px;
          border:4px solid $white;
          opacity: 0;
          border-radius: 50%;
          @include transform ( translate(-50%,-50%) scale(0) );
          transition: 180ms ease-in-out;
        }
      }

      &.slick-active button,
      &:hover button {
        background: $primary;
        &:after{
          opacity: 1;
          @include transform ( translate(-50%,-50%) scale(1) );
        }
      }
    }
  }
}

// Slick carousel complexes
.slick-carousel-complex-variant-1 {
  position: relative;
  padding-bottom: 60px;

  &:after {
    content: '';
    position: absolute;
    top: 80px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 101vw;
    background: $primary;
  }

  > * {
    position: relative;
    z-index: 2;
  }

  .slick-slider {
    margin-bottom: 0;
  }

  // Offsets
  .slick-dots {
    margin-top: 30px;
  }

  @include media-breakpoint-up(md) {
    padding-bottom: 90px;
  }
}

.slick-slider-images {
  .item {
    padding: 0 15px;
    text-align: right;

    img {
      display: inline-block;
      transform: scale(.75);
      transform-origin: 100% 50%;
      will-change: transform;
      cursor: pointer;
      transition: .5s all ease;
    }

    &.slick-center {
      text-align: center;
      img {
        transform-origin: 50% 50%;
        transform: scale(1);
      }
    }

    &.slick-center + * {
      text-align: left;

      img {
        transform-origin: 0 50%;
      }
    }
  }
}

.slick-carousel-round-image {
  .item img {
    border-radius: 50%;
  }

}

.carousel-testimonials-home {

  .slick-slide {
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: left;
    }
  }

  .slick-dots li button {
    background: rgba($whisper, .5);
  }


  .item{
    padding-left: 10px;
    padding-right: 10px;
  }

  @include media-breakpoint-up(xl) {
    .item{
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include media-breakpoint-up(sm) {
    .slick-dots {
      display: none !important;
    }
  }

  .slick-images {
    .item{
      padding-left: 0;
      padding-right: 0;
    }
    .item .imp-wrap {
      text-align: center;
      position: relative;
      padding: 10px;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
        content: '';
        display: inline-block;
        margin-left: 0px;
        border: 1px solid #fccb56;
        width: 116px;
        height: 116px;
        border-radius: 50%;
        transition: 200ms ease-in-out;
        opacity: 0;
        pointer-events: none;
      }

      img {
        display: inline-block;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .item:hover,
    .item.slick-current {
      .imp-wrap:after {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
  }

  .quote-desc  {
    @include justify-content(center);
  }
}